import { datadogRum } from '@datadog/browser-rum';
import styled from '@emotion/styled';
import { notify } from '@genteras/ui-lib/base';
import { Turnstile } from '@marsidev/react-turnstile';
import { Form, Input, Row } from 'antd';
import axios from 'axios';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Router from 'next/router';
import Script from 'next/script';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';
import { popupFormCss } from '@/components/common/Form';
import { urls } from 'constant/urls';
import type { SiteConfig } from '../../config';
import config, { Sites } from '../../config';
import UserAPI from '../../lib/api/user';
import { commonConfig } from '../../lib/i18n/configs';
import { StyledButtonThick } from '../base/StyledButtonThick';
import { StyledInput } from '../base/StyledInput';

interface FormData {
  username: string;
  password: string;
}

type Props = {
  name: string;
};

const CenterButtonDiv = styled.div({
  justifyContent: 'center',
  display: 'flex',
});

const CenterIconDiv = styled.div({
  margin: 'auto',
});

const StyledCond = styled.div`
  width: 300px;
`;

const FormItem = styled(Form.Item)({
  alignContent: 'center',
  padding: '2px',
  paddingBottom: '20px',
});

const LoginForm: FC<Props> = ({ name }: Props) => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useSWRConfig();
  const RECAPTCHA_SITE_KEY =
    process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY || '';
  const recaptchaRef = React.useRef();

  const handleSubmit = async (values: FormData) => {
    try {
      setIsLoading(true);
      // eslint-disable-next-line
      const token = (recaptchaRef.current as any)?.getResponse();
      const loginData = {
        username: values.username,
        password: values.password,
        types: ['Player'],
        recaptchaToken: token,
      };
      const data = await UserAPI.login(loginData);
      datadogRum.setUser({
        id: data.id,
      });
      mutate('/players/me', data);

      Router.push(urls.play);
      notify.success({
        message: t('common:LoginSuccess'),
      });
    } catch (e: unknown) {
      if (
        axios.isAxiosError(e) &&
        e?.response?.data?.message === 'Unauthorized'
      ) {
        notify.error({
          message: t('common:error.IncorrectUsernameOrPassword'),
          t,
        });
      } else if (
        axios.isAxiosError(e) &&
        e?.response?.data?.message == 'accountFrozen'
      ) {
        notify.error({
          message: t(e?.response?.data?.message),
          t,
        });
      } else if (
        axios.isAxiosError(e) &&
        e?.response?.data?.message === 'Low recaptcha score.'
      ) {
        notify.error({
          message: t('common:error.LOW_RECAPTCHA'),
          t,
        });
      } else {
        notify.error({
          message: t('common:error.GenericFailureMsg'),
          description:
            (axios.isAxiosError(e) && e?.response?.data?.message) ??
            t('common:SomethingWentWrong'),
          t,
        });
        throw e;
      }
    } finally {
      setIsLoading(false);
      // eslint-disable-next-line
      (recaptchaRef.current as any)?.reset();
    }
  };

  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeader: '',
    logoCompact: '',
    favicon: '',
    systemIcon: '',
  });
  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);
  return (
    <div className={'login-form'}>
      <Script
        src="https://challenges.cloudflare.com/turnstile/v0/api.js"
        async={true}
        defer={true}
      />
      <Row>
        <CenterIconDiv>
          {siteConfig.logoCompact ? (
            <div>
              <Image
                src={siteConfig.logoCompact}
                width={200}
                height={160}
                alt="logo"
              />
            </div>
          ) : null}
        </CenterIconDiv>
      </Row>
      <CenterButtonDiv>
        <StyledCond>
          <Form
            form={form}
            css={popupFormCss}
            name={name}
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <FormItem
              name="username"
              rules={[
                {
                  required: true,
                  message: t('common:validation.pleaseInputYourUsername'),
                },
              ]}
            >
              <StyledInput
                style={{ height: '40px' }}
                placeholder={t('common:username')}
              />
            </FormItem>

            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: t('common:validation.pleaseInputYourPassword'),
                },
              ]}
            >
              <Input.Password
                style={{ height: '40px', borderRadius: '50px' }}
                placeholder={t('common:password')}
              />
            </FormItem>

            <FormItem>
              <Turnstile ref={recaptchaRef} siteKey={RECAPTCHA_SITE_KEY} />
              <StyledButtonThick
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {t('common:header.login')}
              </StyledButtonThick>
            </FormItem>
          </Form>
        </StyledCond>
      </CenterButtonDiv>
    </div>
  );
};

export default LoginForm;
